////////////////////////////////////////////////////////
////////     CORE DASHLITE VARIABLES    ///// //////////
////////////////////////////////////////////////////////

@use 'sass:math';
$font-url: '../../../fonts';

/////////    COLORS    ///////////
//////////////////////////////////////
$white: #fff;
$black: #000;

$light-100: #ebeef2;
$light-200: #e5e9f2;
$light-300: #dbdfea;
$light-400: #b7c2d0;
$light-500: #8091a7;
$light-600: #3c4d62;
$light-700: #344357;
$light-800: #2b3748;
$light-900: #1f2b3a;

$lighter: #f5f6fa;
$light: $light-100;
$dark: $light-900;
$darker: #101924;


$red: #e85347;
$yellow: #f4bd0e;
$green: #1ee0ac;
$cyan: #09c2de;
$blue: #559bfb;
$azure: #1676fb;
$indigo: #2c3782;
$purple: #816bff;
$pink: #ff63a5;
$orange: #ffa353;
$teal: #20c997;

// Base/Default color @used for text
$base-100: #ecf2ff;
$base-200: #dfe9fe;
$base-300: #b6c6e3;
$base-400: #8094ae;
$base-500: #526484;

$base-color: #364a63;
$base-dark: #1c2b46;
$base-text: $base-500;
$base-light: $base-400;
$base-dim: mix($base-color, $white, 8%);

// Base/Default color @used for text
// $accent-100:        #f0eaff;
// $accent-200:        #e7dcff;
// $accent-300:        #d4c2ff;
// $accent-400:        #b695ff;
// $accent-500:        #9d72ff;

// $accent-color:      #854fff;
// $accent-light:      $accent-300;
// $accent-dark:       #3a2272;
// $accent-dim:  	    #e3e7fe;

// Base/Default color @used for text
$accent-100: #ecf2ff;
$accent-200: #dfe9fe;
$accent-300: #b6c6e3;
$accent-400: #8094ae;
$accent-500: #526484;

$accent-color: #364a63;
$accent-dark: #1c2b46;
$base-text: $base-500;
$accent-light: $base-400;
$base-dim: mix($base-color, $white, 8%);

$body-bg: $lighter;
$body-color: $base-text;

$link-color: $accent-500;
$link-hover-color: darken($link-color, 10%);

$shadow-color: $light-800;
$shadow-color-light: $light-600;
$shadow-color-dark: $light-900;
$shadow-color-accent: $accent-dark;

// Status Color
$success: $green;
$warning: $yellow;
$danger: $red;
$info: $cyan;

// Brand Colors
$brand-color-bitcoin: #f9841e;
$brand-color-ethereum: #6174b9;
$brand-color-facebook: #3b5998;
$brand-color-google: #de5246;

$extend-mix: #fff;
$extend-colors: (
    "blue": $blue,
    "azure": $azure,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "orange": $orange,
    "teal": $teal,
    "blue-dim": mix($blue, $extend-mix, 13%),
    "azure-dim": mix($azure, $extend-mix, 13%),
    "indigo-dim": mix($indigo, $extend-mix, 13%),
    "purple-dim": mix($purple, $extend-mix, 13%),
    "pink-dim": mix($pink, $extend-mix, 13%),
    "orange-dim": mix($orange, $extend-mix, 13%),
    "teal-dim": mix($teal, $extend-mix, 13%),
    // for bootstrap
    "primary-dim": mix($accent-color, $extend-mix, 13%),
    "success-dim": mix($success, $extend-mix, 13%),
    "info-dim": mix($info, $extend-mix, 13%),
    "warning-dim": mix($warning, $extend-mix, 13%),
    "danger-dim": mix($danger, $extend-mix, 13%),
    "secondary-dim": #e5ecf5,
    "dark-dim": #d9e1ef,
    "gray-dim": #edf2f9
);

/////////    GLOBAL UTILITY    //////////
/////////////////////////////////////////

$bg-light: $light-100;
$bg-light-alt: $light-300;

$bg-dark: $light-600;
$bg-dark-alt: $light-800;

$border-light: $light-200;
$border-light-alt: $light-400;

$border-dark: $light-600;
$border-dark-alt: $light-700;

$spacer: 1rem;

$border-width: 1px;
$border-color: $light-300;

$border-radius: 4px;
$border-radius-lg: 5px;
$border-radius-sm: 3px;
$border-radius-xl: 8px;
$border-radius-xxl: 12px;

$rounded: 50%;
$rounded-pill: 1.5rem;

$box-shadow-sm: 0 .125rem .25rem rgba($shadow-color, .15);
$box-shadow: 0 3px 12px 1px rgba($shadow-color, .15);
$box-shadow-lg: 0 1rem 3rem rgba($shadow-color, .25);

$fx-sz-9: 9px;
$fx-sz-10: 10px;
$fx-sz-11: 11px;
$fx-sz-12: 12px;
$fx-sz-13: 13px;
$fx-sz-14: 14px;
$fx-sz-15: 15px;
$fx-sz-16: 16px;

$gutter-width: 28px;
$stretch-gap-x: (
    4px + math.div($gutter-width, 2)
);

/////////    TYPTOGRAPHY    //////////
//////////////////////////////////////

$base-font-family: "DM Sans", sans-serif;
$base-line-height: 1.65;
$alt-font-family: $base-font-family;

$lg-base: $base-line-height;
$lh-rg: $base-line-height;
$lh-lg: 1.5;
$lh-sm: 1.5;

$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-700: 700;

$fw-base: $fw-400;
$fw-normal: $fw-400;
$fw-bold: $fw-700;
$fw-light: $fw-300;
$fw-medium: $fw-500;

$fs-base-dsk: 16px;
$fs-base-tab: 16px;
$fs-base-mob: 16px;

$fs-base: 0.875rem; // $fs-base-px is `16px`
$fs-lg: 1.25rem;
$fs-sm: .75rem;

$h1-fs: 2rem;
$h2-fs: 1.75rem;
$h3-fs: 1.5rem;
$h4-fs: 1.25rem;
$h5-fs: 1.15rem;
$h6-fs: 1rem;
// desktop
$h1-fs-lg: 3.75rem;
$h2-fs-lg: 2.5rem;
$h3-fs-lg: 2rem;
$h4-fs-lg: 1.5rem;
$h5-fs-lg: 1.25rem;
$h6-fs-lg: 1.05rem;

$page-title-ff: $alt-font-family;
$page-title-fs: 1.5rem;
$page-title-fs-lg: 1.75rem;
$page-title-fw: $fw-bold;

$h-margin-bottom: math.div($spacer , 2);
$h-font-family: $alt-font-family;
$h-font-weight: $fw-bold;
$h-line-height: 1.1;
$h-color: $base-color;

$lead-fs: $fs-base * 1.15;
$lead-fs-dsk: $fs-base * 1.25;
$lead-fw: $fw-normal;

$small-fs: 85%;

$text-off: $base-light;


$quote-small-color: $base-300;
$quote-small-font-size: $small-fs;
$quote-font-size: $fs-base * 1.25;


$mark-padding: .2em;
$mark-bg: #fcf8e3;

$hr-color: $light-400;
$hr-width: 1px;
$hr-margin: $spacer;
$para-margin: 1rem;


/////////    FORMS GLOBAL    /////////////////
//////////////////////////////////////////////

$field-padding-y: 0.4375rem;
$field-padding-x: 1rem;
$field-font-family: null;
$field-font-size: $fx-sz-13;
$field-line-height: 1.25rem;
$field-font-weight: $fw-base;

$field-focus-width: .2rem;
$field-focus-color: $accent-color;
$field-focus-shadow: 0 0 0 3px;
$field-focus-box-shadow: $field-focus-shadow rgba($field-focus-color, .1);

$field-padding-y-sm: .25rem;
$field-padding-x-sm: 1rem;
$field-font-size-sm: $fx-sz-12;
$field-line-height-sm: 1.25rem;

$field-padding-y-lg: .6875rem;
$field-padding-x-lg: 1rem;
$field-font-size-lg: $fx-sz-15;
$field-line-height-lg: 1.25rem;

$field-padding-y-xl: 0.625rem;
$field-padding-x-xl: 1.25rem;
$field-font-size-xl: $fx-sz-15;
$field-line-height-xl: 2rem;

$field-border-width: $border-width;

$field-margin-bottom: 16px;
$field-margin-bottom-lg: 20px;
$field-margin-bottom-sm: 12px;

$field-bg: $white;
$field-bg-disable: $lighter;
$field-color: $light-600;
$field-border-color: $light-300;

$field-error-color: $danger;
$field-error-border-color: $danger;

$field-placeholder-color: $base-300;
$field-plaintext-color: $base-color;

$field-border-radius: $border-radius;
$field-border-radius-sm: $border-radius-sm;
$field-border-radius-lg: $border-radius-lg;
$field-border-radius-xl: $border-radius-lg;

/////////    BUTTONS    /////////////////
/////////////////////////////////////////

$btn-padding-y: $field-padding-y;
$btn-padding-x: $field-padding-x * 1.125;
$btn-font-family: $alt-font-family;
$btn-font-size: $fx-sz-13;
$btn-line-height: $field-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $field-padding-y-sm;
$btn-padding-x-sm: $field-padding-x-sm * 0.75;
$btn-font-size-sm: $fx-sz-12;
$btn-line-height-sm: $field-line-height-sm;

$btn-padding-y-lg: $field-padding-y-lg;
$btn-padding-x-lg: $field-padding-x-lg * 1.5;
$btn-font-size-lg: $fx-sz-15;
$btn-line-height-lg: $field-line-height-lg;

$btn-padding-y-xl: $field-padding-y-xl;
$btn-padding-x-xl: 2rem;
$btn-font-size-xl: 18px;
$btn-line-height-xl: $field-line-height-xl;

$btn-padding-y-xs: .125rem;
$btn-padding-x-xs: .5rem;
$btn-font-size-xs: 0.6875rem;
$btn-line-height-xs: 1rem;

$btn-border-width: $field-border-width;

$btn-font-weight: $fw-bold;
$btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($darker, .075);
$btn-focus-width: $field-focus-width;
$btn-focus-box-shadow: $field-focus-box-shadow;
$btn-disabled-opacity: .5;
$btn-active-box-shadow: inset 0 3px 5px rgba($darker, .125);

$btn-link-disabled-color: $field-bg-disable;

$btn-block-spacing-y: .5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $field-border-radius;
$btn-border-radius-lg: $field-border-radius-lg;
$btn-border-radius-sm: $field-border-radius-sm;
$btn-border-radius-xs: 3px;

$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;


/////////    FORMS    /////////////////
///////////////////////////////////////

$input-padding-y: $field-padding-y;
$input-padding-x: $field-padding-x;
$input-font-family: $field-font-family;
$input-font-size: $field-font-size;
$input-font-weight: $field-font-weight;
$input-line-height: $field-line-height;

$input-padding-y-sm: $field-padding-y-sm;
$input-padding-x-sm: $field-padding-x-sm;
$input-font-size-sm: $field-font-size-sm;
$input-line-height-sm: $field-line-height-sm;

$input-padding-y-lg: $field-padding-y-lg;
$input-padding-x-lg: $field-padding-x-lg;
$input-font-size-lg: $field-font-size-lg;
$input-line-height-lg: $field-line-height-lg;

$input-padding-y-xl: $field-padding-y-xl;
$input-padding-x-xl: $field-padding-x-xl;
$input-font-size-xl: $field-font-size-xl;
$input-line-height-xl: $field-line-height-xl;

$input-bg: $field-bg;
$input-disabled-bg: $field-bg-disable;

$input-color: $field-color;
$input-border-color: $field-border-color;
$input-border-width: $field-border-width;
$input-box-shadow: inset 0 1px 1px rgba($darker, .075);

$input-border-radius: $field-border-radius;
$input-border-radius-xl: $field-border-radius-xl;
$input-border-radius-lg: $field-border-radius-lg;
$input-border-radius-sm: $field-border-radius-sm;

$input-focus-bg: $field-bg;
$input-focus-border-color: $field-focus-color;
$input-focus-color: $field-color;
$input-focus-width: $field-focus-width;
$input-focus-box-shadow: $field-focus-box-shadow;

$input-error-color: $field-color;
$input-error-border-color: $field-error-border-color;
$input-error-focus-box-shadow: $field-focus-shadow rgba($field-error-border-color, .1);

$input-placeholder-color: $field-placeholder-color;
$input-plaintext-color: $field-plaintext-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: $input-line-height + $input-padding-y * 2;
$input-height-inner-sm: $input-line-height-sm + $input-padding-y-sm * 2;
$input-height-inner-lg: $input-line-height-lg + $input-padding-y-lg * 2;
$input-height-inner-xl: $input-line-height-xl + $input-padding-y-xl * 2;
$input-height-inner-half: add($input-line-height * .5, $input-padding-y);
$input-height-inner-quarter: add($input-line-height * .25, math.div($input-padding-y , 2));

$input-height: calc(#{$input-height-inner} + #{$input-height-border});
$input-height-sm: calc(#{$input-height-inner-sm} + #{$input-height-border});
$input-height-lg: calc(#{$input-height-inner-lg} + #{$input-height-border});
$input-height-xl: calc(#{$input-height-inner-xl} + #{$input-height-border});

$input-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;


$textarea-min-height: 122px;
$textarea-min-height-sm: 92px;
$textarea-min-height-lg: 208px;


/////////    CARDS    ////////////////
///////////////////////////////////////

$card-spacer-y: .75rem;
$card-spacer-x: 1.25rem;
$card-spacer-sm-x: 1.5rem;
$card-spacer-large: 2.5rem;

$card-border-width: 0;
$card-border-radius: $border-radius;
$card-border-color: rgba($black, .125);
$card-inner-border-radius: (
    $card-border-radius - 1
);
$card-cap-bg: rgba($black, .07);
$card-cap-color: null;
$card-color: null;
$card-bg: $white;

$card-img-overlay-padding: 1.25rem;

$card-group-margin: math.div($gutter-width , 2);
$card-deck-margin: $card-group-margin;

$card-columns-count: 3;
$card-columns-gap: 1.25rem;
$card-columns-margin: $card-spacer-y;

// IO 
$card-bordered-color: $light-300;
$card-stretch-gap-x: $stretch-gap-x;


/////////    ALERTS    ////////////////
///////////////////////////////////////

$alert-padding-y: 1rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 2rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $fw-bold;
$alert-border-width: $border-width;

$alert-bg-level: -11;
$alert-border-level: -8;
$alert-color-level: 2;

// // IO
$alert-icon-width: 2rem;
$alert-pro-icon-width: 2.75rem;


/////////    BADGES    ////////////////
///////////////////////////////////////

$badge-font-size: .675rem;
$badge-line-height: 1.125rem;
$badge-font-weight: $fw-medium;
$badge-padding-y: 0;
$badge-padding-x: .375rem;
$badge-border-radius: $border-radius-sm;

$badge-transition: $btn-transition;
$badge-focus-width: $field-focus-width;

$badge-pill-padding-x: .5rem;
$badge-pill-border-radius: 10rem;

$badge-dot-width: 6px;
$badge-dot-gap: 6px;

$badge-dot-width-sm: 8px;
$badge-dot-gap-sm: 8px;

// IO

$badge-font-size-sm: .75rem;
$badge-padding-y-sm: .3125rem;
$badge-padding-x-sm: .75rem;
$badge-pill-padding-x-sm: .875rem;

$badge-font-size-md: .8125rem;
$badge-padding-y-md: .47rem;
$badge-padding-x-md: 1rem;

$badge-font-size-lg: .93rem;
$badge-padding-y-lg: .75rem;
$badge-padding-x-lg: 1.25rem;

$badge-font-size-xl: 1.125rem;
$badge-padding-y-xl: .875rem;
$badge-padding-x-xl: 1.5rem;



/////////    BREADCRUMB    ////////////////
///////////////////////////////////////////

$breadcrumb-font-size: null;

$breadcrumb-padding-y: .25rem;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding: .5rem;

$breadcrumb-margin-bottom: 0;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $light-500;
$breadcrumb-active-color: $light-400;
$breadcrumb-divider: quote("/");

$breadcrumb-border-radius: $border-radius;

// IO
$breadcrumb-font-size: 11px;
$breadcrumb-font-weight: 500;
$breadcrumb-font-case: uppercase;
$breadcrumb-letter-spacing: 0.05rem;
$breadcrumb-not-hover-color: $base-light;



/////////    DROPDOWNS    /////////////////
///////////////////////////////////////////

$dropdown-min-width: 180px;
$dropdown-padding-y: 0;
$dropdown-spacer: .125rem;
$dropdown-font-size: 13px;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $light-200;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: calc(#{$dropdown-border-radius} - #{$dropdown-border-width});
$dropdown-divider-bg: $light-200;
$dropdown-divider-margin-y: 12px;
$dropdown-box-shadow: 0 3px 12px 1px rgba($accent-dark, .15);

$dropdown-link-color: $base-color;
$dropdown-link-hover-color: $accent-color;
$dropdown-link-hover-bg: $light-100;

$dropdown-link-active-color: $accent-color;
$dropdown-link-active-bg: $light-300;

$dropdown-link-disabled-color: $light-100;

$dropdown-item-padding-y: 8px;
$dropdown-item-padding-x: 14px;

$dropdown-header-color: $light-500;

// IO
$dropdown-width-xl: 360px;
$dropdown-width-lg: 320px;
$dropdown-width-md: 280px;
$dropdown-width-sm: 140px;
$dropdown-width-xs: 120px;
$dropdown-width-xxs: 70px;



/////////    PAGINATION    ////////////////
///////////////////////////////////////////

$pagination-padding-y: .5625rem;
$pagination-padding-x: .625rem;
$pagination-padding-y-sm: .4375rem;
$pagination-padding-x-sm: .5rem;
$pagination-padding-y-lg: .5625rem;
$pagination-padding-x-lg: .75rem;
$pagination-padding-y-xl: .875rem;
$pagination-padding-x-xl: .75rem;
$pagination-line-height: 1rem;
$pagination-line-height-sm: .875rem;
$pagination-line-height-lg: 1.5rem;
$pagination-line-height-xl: 1.5rem;

$pagination-color: $base-text;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-color: $light-200;

$pagination-focus-box-shadow: none;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $light-100;
$pagination-hover-border-color: $light-200;

$pagination-active-color: $white;
$pagination-active-bg: $accent-color;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $light-300;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $light-200;

// IO
$pagination-font-size: .8125rem;

$tooltip-font-size: $fx-sz-13;
$tooltip-bg: $dark;
$tooltip-border-radius: $border-radius-sm;
$tooltip-padding-x: .75rem;
$tooltip-arrow-color: $tooltip-bg;


/////////    Custom Controls    ///////////////
///////////////////////////////////////
$checkbox-radio-switch-size: 1.5rem;
$checkbox-radio-switch-size-sm: 1.125rem;
$checkbox-radio-switch-size-lg: 2rem;
$checkbox-radio-switch-border: 2px;

$checkbox-radio-switch-text-gap: .75rem;
$checkbox-radio-switch-text-gap-sm: .5rem;
$checkbox-radio-switch-text-gap-lg: 1rem;

$checkbox-radio-switch-font-size: $fx-sz-14;
$checkbox-radio-switch-font-size-sm: $fx-sz-12;
$checkbox-radio-switch-font-size-lg: $fx-sz-15;

$checkbox-radio-switch-line-height: 1.25rem;
$checkbox-radio-switch-line-height-sm: 1.125rem;
$checkbox-radio-switch-line-height-lg: 1.375rem;

$switch-width: $checkbox-radio-switch-size * 2;
$switch-width-sm: $checkbox-radio-switch-size-sm * 1.75;
$switch-width-lg: $checkbox-radio-switch-size-lg * 2;

$switch-indicator-border-radius: math.div($switch-width , 2);
$switch-indicator-border-radius-sm: math.div($switch-width-sm , 2);
$switch-indicator-border-radius-lg: math.div($switch-width-lg , 2);

$switch-indicator-size: 1rem;
$switch-indicator-size-sm: 0.65rem;
$switch-indicator-size-lg: 1.25rem;

//calc ver
$switch-border-calc: $checkbox-radio-switch-border*2;
$switch-border-calc-1-5x: $checkbox-radio-switch-border*3;
$switch-border-calc-2x: $checkbox-radio-switch-border*4;
$switch-border-calc-3x: $checkbox-radio-switch-border*6;
$switch-indicator-active-clac: $switch-width - $switch-indicator-size;
$switch-indicator-active-clac-sm: $switch-width-sm - $switch-indicator-size-sm;
$switch-indicator-active-clac-lg: $switch-width-lg - $switch-indicator-size-lg;

$switch-indicator-active-position: calc(#{$switch-indicator-active-clac} - #{$switch-border-calc-2x});
$switch-indicator-active-position-sm: calc(#{$switch-indicator-active-clac-sm} - #{$switch-border-calc-2x});
$switch-indicator-active-position-lg: calc(#{$switch-indicator-active-clac-lg} - #{$switch-border-calc-3x});

$switch-indicator-active-position-right: calc((-#{$switch-indicator-active-clac} + #{$switch-border-calc-2x}));
$switch-indicator-active-position-right-sm: calc((-#{$switch-indicator-active-clac-sm} + #{$switch-border-calc-2x}));
$switch-indicator-active-position-right-lg: calc((-#{$switch-indicator-active-clac-lg} + #{$switch-border-calc-3x}));

/////////    Modals    ///////////////
///////////////////////////////////////
$modal-fade-transform: translate(0, -30px);
$modal-fade-transform-revarse: translate(0, -10px);
$modal-show-transform: none !important;
$modal-transition: transform .3s ease-out;
$modal-scale-transform: scale(.95);

$modal-inner-padding: 1.25rem;
$modal-header-padding-y: 1rem;
$modal-header-padding-x: $modal-inner-padding;



$modal-custom-padding: 1.5rem;
$modal-custom-padding-sm: 1.25rem;
$modal-custom-padding-md: 2.25rem;
$modal-custom-padding-lg: 3.75rem;

$modal-body-custom-padding-x: 1.5rem;
$modal-body-custom-padding-x-sm: 1.25rem;
$modal-body-custom-padding-x-md: 2.5rem;
$modal-body-custom-padding-x-lg: 3.75rem;

$modal-header-custom-padding-xy: 1rem $modal-custom-padding;
$modal-header-custom-padding-xy-sm: 0.75rem $modal-body-custom-padding-x-sm;

$modal-footer-custom-padding-xy: 1rem $modal-custom-padding;
$modal-footer-custom-padding-xy-sm: 0.75rem $modal-body-custom-padding-x-sm;


// $modal-body-padding-md-y:              3.75rem;

// $modal-body-padding-lg-y:              3.75rem;

// $modal-header-padding-md-y:            3.75rem;
// $modal-header-padding-lg-y:            3.75rem;

// $modal-footer-padding-md-y:            3.75rem;
// $modal-footer-padding-lg-y:            3.75rem;


/////////    LAYOUTS    ///////////////
///////////////////////////////////////

$min-body-width: 320px;

// Logo
$logo-height: 36px;
$logo-height-sm: 28px;
$logo-height-lg: 60px;
$logo-height-icon: 33px;

// Sidebar
$sidebar-width-normal: 290px;
$sidebar-width-compact: 74px;
$sidebar-gap-x: 24px;

$sidebar-width-fat: 400px;
$sidebar-width-fat-tablet: 340px;
$sidebar-gap-x-fat: 32px;
$sidebar-gap-x-fat-tablet: 16px;
$sidebar-fat-transition-name: ease;
$sidebar-fat-transition-duration: 450ms;

// Apps Sidebar
$sidebar-width-apps: 80px;
$sidebar-gap-x-apps: 24px;
$sidebar-apps-transition-name: ease;
$sidebar-apps-transition-duration: 450ms;

$sidebar-transition-name: ease;
$sidebar-transition-duration: 450ms;
$sidebar-overlay: rgba($darker, .4);

$sidebar-bg-color: $lighter;
$sidebar-border-color: $light-200;

$sidebar-bg-dark-color: $darker;
$sidebar-border-dark-color: lighten($sidebar-bg-dark-color, 10%);

$sidebar-bg-theme-color: darken($accent-dark, 2%);
$sidebar-border-theme-color: lighten($sidebar-bg-theme-color, 10%);

$sidebar-bg-light-color: $white;
$sidebar-border-light-color: $light;

$sidebar-toogle-btn-color: $base-500;

// Content
$content-gap-x: 20px - math.div($gutter-width, 2);
$content-gap-x-sm: 36px - math.div($gutter-width, 2);
$content-gap-y: 24px;
$content-sidebar-width: 280px;

$content-sidebar-bg-color: $white;
$content-sidebar-border-color: $border-light;

// // Content

// // Headers
$header-main-gap-x: $content-gap-x;
$header-main-gap-x-sm: $content-gap-x-sm;
$header-main-gap-y: 14px;
$header-main-border-width: 1px;
$header-height: (
    $header-main-gap-y*2 + $logo-height + $header-main-border-width
);
$header-min-width: $min-body-width;

$header-bg-color: $lighter;
$header-bg-dark-color: $darker;
$header-bg-theme-color: darken($accent-dark, 2%);
$header-border-bottom: $border-light;
$header-border-dark-bottom: lighten($header-bg-dark-color, 10%);
$header-border-theme-bottom: lighten($header-bg-theme-color, 10%);
$header-toogle-btn-color: $base-500;
$header-toogle-btn-focus-color: $accent-color;
$header-toogle-btn-border: $btn-border-width solid transparent;
$header-toogle-btn-lh: $btn-line-height-sm;

$header-toogle-btn-icon-width: $btn-line-height-sm + $btn-padding-y-sm*2;

$header-col-width-small: 120px;
$header-col-width-safe: math.div($header-col-width-small, 2);
$header-col-width: calc(50% - #{$header-col-width-safe});

//Footer
$footer-main-gap-x: $content-gap-x;
$footer-main-gap-x-sm: $content-gap-x-sm;
$footer-main-gap-y: 20px;
$footer-main-border-width: 1px;
$footer-min-width: $min-body-width;

$footer-bg-color: $white;
$footer-bg-dark-color: darken($accent-dark, 1%);
$footer-border-bottom: $border-light;


// Aside
$aside-bg: $white;
$aside-content-body-height-calc: (
    $content-gap-y + 8 + $header-height
);


$menu-icon-width: 36px;
$menu-icon-width-sm: 32px;


//////  CONFIGURATION VARIABLE  //////
$dark_option: true;
$dark_theme_option: true;
$gd_investment: true;

///////////////////////////////////////////////

/////////    THIRD-PARTY VARIABLE    //////////
///////////////////////////////////////////////
$nk-dashlite-font: "Nioicon";
$nk-dashlite-font-url: "../../../fonts";
@import "vendors/nioicon/variables";

///////////////////////////////////////////////

/////////    KeyFrame For Loader    //////////
///////////////////////////////////////////////
@keyframes LoginLoader {
    0% {
        left: -30%;
    }

    50% {
        left: 80%;
    }

    100% {
        left: -30%;
    }
}