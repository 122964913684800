//// User Avatar 
/////////////////////
.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background: $accent-500;
    font-size: 14px;
    font-weight: $fw-bold;
    letter-spacing: 0.06em;
    flex-shrink: 0;
    position: relative;

    +.user-info {
        margin-left: 1rem;
    }

    +.user-name {
        margin-left: 0.75rem;
    }

    img {
        border-radius: 50%;
    }

    &.md {
        +.user-info {
            margin-left: 1.5rem;
        }

        +.user-name {
            margin-left: 1.25rem;
        }
    }

    .edit {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        background-color: rgba($dark, .5);
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        opacity: 0;
        transition: opacity .3s;
    }

    &:hover {
        .edit {
            opacity: 1;
        }
    }

    .status {
        border: 2px solid $white;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &.sq {
        border-radius: $border-radius;

        img {
            border-radius: $border-radius;
        }

        .status {
            right: -6px;
            bottom: -6px;
        }
    }

    &.not_active {
        color: $primary;
        background: $white;
        border: 1px solid;

        &.complete {
            border-color: $primary;
        }

        &.not_complete {
            border-color: $light-400;
        }
    }
}

// Multiple Avatar
.user-avatar-multiple {
    background-color: transparent !important;

    .user-avatar {
        position: absolute;
        height: 70%;
        width: 70%;
        font-size: 80%;
        border: 2px solid $white;

        &:nth-child(1) {
            top: 0;
            right: 0;
        }

        &:nth-child(2) {
            bottom: 0;
            left: 0;
        }
    }
}

.user-card .user-avatar.d-none {
    margin-right: 1rem;

    +.user-info,
    +.user-name {
        margin-left: 0;
    }
}

// Sizes
.user-avatar {

    &-xl,
    &.xl {
        height: 110px;
        width: 110px;
        font-size: 40px;
        font-weight: 400;

        .status {
            bottom: 9px;
            right: 9px;
        }
    }

    &-lg,
    &.lg {
        height: 80px;
        width: 80px;
        font-size: 28px;
        font-weight: 400;

        .status {
            bottom: 5px;
            right: 5px;
        }
    }

    &-md,
    &.md {
        height: 60px;
        width: 60px;
        font-size: 18px;

        .status {
            bottom: 2px;
            right: 2px;
        }
    }

    &-sm,
    &.sm {
        height: 32px;
        width: 32px;
        font-size: 12px;

        .status {
            bottom: -2px;
            right: -2px;
        }
    }

    &-xs,
    &.xs {
        height: 26px;
        width: 26px;
        font-size: 9px;
        font-weight: $fw-bold;

        .status {
            bottom: -2px;
            right: -2px;
        }
    }
}

// Colors Fix
$avatar-adj-colors: (
    "blue-dim": $blue,
    "azure-dim": $azure,
    "indigo-dim": $indigo,
    "purple-dim": $purple,
    "pink-dim": $pink,
    "orange-dim": $orange,
    "teal-dim": $teal,
    // for bootstrap
    "primary-dim": $accent-color,
    "secondary-dim": $base-color,
    "success-dim": $success,
    "info-dim": $info,
    "warning-dim": $warning,
    "danger-dim": $danger,
    "dark-dim": $dark,
    "gray-dim": $light-500,
    'lighter': $base-light,
    'light': $base-text
);

@each $name,
$color in $avatar-adj-colors {
    .user-avatar[class*="-#{$name}"] {
        color: $color;
    }
}


// User Card
//////////////////////////
.user {
    &-card {
        display: flex;
        align-items: center;

        &-wrap {
            padding-top: 1.125rem;
            padding-bottom: 1.125rem;
        }

        .user-info {
            color: $base-light;
        }

        &-menu {
            position: absolute;
            right: 1rem;
            top: 1rem;
        }
    }

    &-toggle {
        display: flex;
        align-items: center;
        padding: 0 1px 1px;
    }

    &-status {
        font-size: $fx-sz-11;
        font-weight: 500;
        line-height: 1;
        padding-top: .125rem;
        padding-bottom: .375rem;

        &-unverified {
            color: $danger;
        }

        &-verified {
            color: $success;
        }
    }

    &-name {
        color: $base-text;
        font-size: $fx-sz-13;
        line-height: 16px;
        font-weight: $fw-bold;

        @if($dark_option==true) {
            .is-dark & {
                color: lighten($base-light, 5%);
            }
        }

        @if($dark_theme_option==true) {
            .is-theme & {
                color: $accent-light;
            }
        }
    }

    &-action {
        margin-left: auto;
        font-size: 20px;
        color: $base-light;
    }

    &-account {
        &-info {
            padding-top: 1.5rem;
            padding-bottom: 1.25rem;
        }

        &-data {
            li {
                display: flex;
                justify-content: space-between;
                padding: .125rem 0;
            }
        }

        &-actions {
            &:not(:first-child) {
                margin-top: 1.5rem;
            }

            ul {
                display: flex;

                li {
                    width: 44%;

                    &:last-child {
                        width: 56%;
                    }
                }
            }

            .btn {
                width: 100%;
                font-size: 12px;
                letter-spacing: 0.04em;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                text-transform: uppercase;
            }
        }

        &-links {
            padding: 1rem 0;
            border-bottom: 1px solid $border-light;

            &:last-child {
                .user-account-info & {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }

            .user-account-info+& {
                padding-top: 0;
                margin-top: -0.5rem;
            }

            a:link {
                padding: 0.5rem 0;
                display: flex;
            }
        }

        &-value {
            display: inline-flex;
        }
    }

    &-balance {
        font-size: 1.5rem;
        line-height: 1;
        padding: .125rem 0 .375rem;
        color: $primary;

        small {
            font-size: 1rem;
        }

        &-alt {
            font-size: $fx-sz-15;
            color: $base-text;
            font-weight: $fw-bold;

            span {
                font-weight: $fw-normal;
            }
        }

        &-sub {
            font-size: $fx-sz-13;
            color: $base-light;

            span {
                color: $base-text;
            }

            +a {
                margin-top: 1rem;
            }
        }
    }
}



// user card s2
///////////////////////
.user-card-s2 {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: .5rem;

    .user-avatar {
        margin: 0 auto;
    }

    .user-info {
        margin: 1.25rem 0 0 !important;

        .sub-text {
            justify-content: center;
        }

        .badge {
            margin-bottom: 1rem;
        }
    }
}

// user card md
///////////////////////
.user-card-md {
    .lead-text {
        font-size: 1rem;
    }

    .sub-text {
        font-size: 0.875rem;
    }
}

// User Activity
///////////////////////
.user-activity {
    display: flex;

    &-group {
        display: flex;
        flex-wrap: wrap;
    }

    &-ck {
        height: 110px;
        padding: .5rem .375rem 0;
    }

    .icon {
        width: 2.25rem;
        font-size: 1.5rem;
        color: $base-light;
    }

    .info {
        span {
            display: block;
        }
    }

    .amount {
        font-size: 1.125rem;
        line-height: 1.3;
        font-weight: $fw-medium;
        color: $base-text;
    }

    .title {
        font-size: $fx-sz-12;
        color: $base-light;
    }

    .gfx {
        align-self: flex-end;
        width: 50px;
        margin-left: 1rem;
        margin-bottom: 0.25rem;
    }
}

// User Contact
///////////////////////
.user-contacts {
    li {
        color: $base-text;
        display: flex;
        font-size: .875rem;
        line-height: 1.4rem;
        padding: .375rem 0;

        .icon {
            color: $base-light;
            font-size: 1rem;
            width: 1.75rem;
            line-height: inherit;
            display: inline-block;
        }
    }
}

// User Avatar Group
///////////////////////
.user-avatar-group {
    display: flex;

    .user-avatar {
        border: 2px solid $white;

        &:not(:first-child) {
            margin-left: -20%;
        }

        &.sm:not(:first-child) {
            margin-left: -1rem;
        }

        &.xs:not(:first-child) {
            margin-left: -0.5rem;
        }
    }
}

// User info extend
///////////////////////
.user-info {

    .lead-text,
    .sub-text {
        display: flex;
        align-items: center;
    }

    .edit {
        font-size: 1.25rem;
        color: $base-light;
        margin-left: 0.5rem;
        display: inline-flex;

        &:hover {
            color: $accent-color;
        }
    }
}

// User Plan
///////////////////////
.user-plan {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: .75rem 0;

    &-title {
        display: flex;
        align-items: center;
        color: $base-color;
        font-weight: $fw-bold;
        font-size: 1rem;

        .icon {
            font-size: 1.5rem;
            margin-right: 0.75rem;
            color: $accent-color;
        }
    }

    &-info,
    &-actions {
        padding: 0.5rem 0;
    }

    &-status {
        font-size: $fx-sz-13;
        font-weight: $fw-medium;
        color: $base-light;
        text-transform: uppercase;
        margin-top: 0.5rem;
    }

    &-progress {
        width: 100%;
        margin-top: 1rem;
    }
}