body {
    min-width: $min-body-width;

    &.nav-shown {
        overflow: hidden !important;
    }
}

.nk {
    &-body {
        outline: none;
    }

    &-app {
        &-root {
            outline: none;
        }
    }

    &-main {
        position: relative;
    }

    &-wrap {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    &-content {
        padding: $content-gap-y 4px;

        &-fs {
            min-height: calc(100vh - (#{$header-height} + #{$header-height}));
            display: flex;
            align-items: center;
        }
    }

    &-loader {
        height: 4px;
        background-color: #eee;
        width: 100%;
        margin-top: 30px;
        border-radius: 100px;
        position: relative;
        overflow-x: hidden;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 50%;
            background-color: $primary;
            animation: loading 1.5s infinite;
            -webkit-animation: LoginLoader 3s linear infinite alternate;
        }

    }
}

@include media-breakpoint-up(sm) {
    .nk {
        &-content {
            padding: ($content-gap-y + 8) $content-gap-x-sm;

            &-fluid {
                padding-left: 22px;
                padding-right: 22px;
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .nk {
        &-content {
            &-lg {
                padding-top: ($content-gap-y * 1.5);
                padding-bottom: ($content-gap-y * 1.5);
            }

            &-fluid {
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
}

@media (min-width: 1660px) {
    .nk-content {
        &-lg {
            padding-top: ($content-gap-y * 2.25);
            padding-bottom: ($content-gap-y * 2.25);
        }

        &-fluid {
            padding-left: 44px;
            padding-right: 44px;
        }
    }
}

// Content
.nk-content-body {
    flex-grow: 1;
}

.nk-wrap-nosidebar {
    .nk-content {
        padding: 0 !important;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}